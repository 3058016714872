import payload_plugin_khbsnC89I8 from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_aaw0I19dhx from "/srv/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._ee58621ee9d540d917715341c100d0ed/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EkMjXllc5d from "/srv/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._ee58621ee9d540d917715341c100d0ed/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Lv7oxxIt7D from "/srv/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._ee58621ee9d540d917715341c100d0ed/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_PHVShh1lzC from "/srv/app/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_vite@6.1.0_@types+node@22.13.1_jiti@2.4.2_lightni_827b48ce0b5d78b11ed37f504d5c6f6b/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_Xtyu5YU5Ve from "/srv/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._ee58621ee9d540d917715341c100d0ed/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HyqdfEnzOU from "/srv/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._ee58621ee9d540d917715341c100d0ed/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_owMDPouloX from "/srv/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._ee58621ee9d540d917715341c100d0ed/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_w1s5UgQe8q from "/srv/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._ee58621ee9d540d917715341c100d0ed/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8vLSBCEFBj from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/srv/app/src/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_GWt3574AVR from "/srv/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._ee58621ee9d540d917715341c100d0ed/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Ozodz4Uvzc from "/srv/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_client_uY3QORLGmH from "/srv/app/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import pwa_icons_plugin_iRYm12t59B from "/srv/app/src/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import pwa_client_r868apBSI9 from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_vite@6.1.0_@types+node@22.13.1_jiti@2.4.2_lightnin_e07ef84d5beade8b2ac355acf9893b79/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import siteConfig_mYQTdNCbpQ from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.5_vite@6.1.0_@types+node@22.13.1_jiti@2_b25eea72ced723a4d386d3816aa01e73/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_AI5qdt4SKZ from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.5_vite@6.1.0_@types+node@22.13.1_jiti@2_b25eea72ced723a4d386d3816aa01e73/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_iFBO9273YK from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.5_vite@6.1.0_@types+node@22.13.1_jiti@2_b25eea72ced723a4d386d3816aa01e73/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_4Rlp8jVkNr from "/srv/app/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.34.5_vite@6.1.0_@types+node@22.13.1_jiti@2_b25eea72ced723a4d386d3816aa01e73/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import switch_locale_path_ssr_LexhYNvUg0 from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_043842e07e9a9bfdde711098b85cce70/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_lnHUwcPinW from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_043842e07e9a9bfdde711098b85cce70/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_A1g92gN9Kp from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_043842e07e9a9bfdde711098b85cce70/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_R77ES2gwpj from "/srv/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.5.2_magicast@0.3.5_webpack@5.97.1_esbuild@0.24.2_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import sentry_client_YVXLNBdC17 from "/srv/app/node_modules/.pnpm/@sentry+nuxt@9.0.1_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1_@_a4a44fe9ea1138a3b434615bbbb0845c/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_eX0iNocP1z from "/srv/app/src/node_modules/.cache/nuxt/.nuxt/sentry-client-config.mjs";
import dayjs_qC061yLsl3 from "/srv/app/src/app/plugins/dayjs.ts";
import firebase_client_XDPCT8G2m0 from "/srv/app/src/app/plugins/firebase.client.ts";
import i18n_KYRD8MIJaU from "/srv/app/src/app/plugins/i18n.ts";
import urql_Uu9aPP5RkY from "/srv/app/src/app/plugins/urql.ts";
import ssg_detect_CMC3MnV40W from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.20.1_jiti@2.4.2__magicast@0.3.5_ro_043842e07e9a9bfdde711098b85cce70/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_khbsnC89I8,
  revive_payload_client_aaw0I19dhx,
  unhead_EkMjXllc5d,
  router_Lv7oxxIt7D,
  _0_siteConfig_PHVShh1lzC,
  payload_client_Xtyu5YU5Ve,
  navigation_repaint_client_HyqdfEnzOU,
  check_outdated_build_client_owMDPouloX,
  chunk_reload_client_w1s5UgQe8q,
  plugin_vue3_8vLSBCEFBj,
  components_plugin_zlvi6dcIsi,
  prefetch_client_GWt3574AVR,
  plugin_client_Ozodz4Uvzc,
  plugin_client_uY3QORLGmH,
  pwa_icons_plugin_iRYm12t59B,
  pwa_client_r868apBSI9,
  siteConfig_mYQTdNCbpQ,
  inferSeoMetaPlugin_AI5qdt4SKZ,
  titles_iFBO9273YK,
  defaultsWaitI18n_4Rlp8jVkNr,
  switch_locale_path_ssr_LexhYNvUg0,
  route_locale_detect_lnHUwcPinW,
  i18n_A1g92gN9Kp,
  plugin_R77ES2gwpj,
  sentry_client_YVXLNBdC17,
  sentry_client_config_eX0iNocP1z,
  dayjs_qC061yLsl3,
  firebase_client_XDPCT8G2m0,
  i18n_KYRD8MIJaU,
  urql_Uu9aPP5RkY,
  ssg_detect_CMC3MnV40W
]