
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexm4rxbPD8oHMeta } from "/srv/app/src/app/pages/🫖/index.vue?macro=true";
import { default as create8nUplz1PCVMeta } from "/srv/app/src/app/pages/account/create.vue?macro=true";
import { default as _91username_93gnmmDyDe2eMeta } from "/srv/app/src/app/pages/account/edit/[username].vue?macro=true";
import { default as indexXRwaYtEW5fMeta } from "/srv/app/src/app/pages/account/index.vue?macro=true";
import { default as indexSgtusludH2Meta } from "/srv/app/src/app/pages/account/password/reset/index.vue?macro=true";
import { default as requestZqjHKjTBkfMeta } from "/srv/app/src/app/pages/account/password/reset/request.vue?macro=true";
import { default as verify2K5Zm7V433Meta } from "/srv/app/src/app/pages/account/verify.vue?macro=true";
import { default as _91username_93RZdSdmFdEtMeta } from "/srv/app/src/app/pages/account/view/[username].vue?macro=true";
import { default as index90V81aHL0pMeta } from "/srv/app/src/app/pages/attendance/index.vue?macro=true";
import { default as indexlM1OH2DbxKMeta } from "/srv/app/src/app/pages/contact/index.vue?macro=true";
import { default as index2eI3lMxftTMeta } from "/srv/app/src/app/pages/dashboard/index.vue?macro=true";
import { default as appaS3xQ8Wsx5Meta } from "/srv/app/src/app/pages/docs/app.vue?macro=true";
import { default as browser_45supportCA0fFFkcP4Meta } from "/srv/app/src/app/pages/docs/browser-support.vue?macro=true";
import { default as create4jqXpPsAKAMeta } from "/srv/app/src/app/pages/event/create.vue?macro=true";
import { default as _91event_name_933f9GExsCb0Meta } from "/srv/app/src/app/pages/event/edit/[username]/[event_name].vue?macro=true";
import { default as index2SrXCmp0ZfMeta } from "/srv/app/src/app/pages/event/index.vue?macro=true";
import { default as attendanceNSqYMMoU5aMeta } from "/srv/app/src/app/pages/event/view/[username]/[event_name]/attendance.vue?macro=true";
import { default as guestqrhruBplTSMeta } from "/srv/app/src/app/pages/event/view/[username]/[event_name]/guest.vue?macro=true";
import { default as indexkrooqXKMT7Meta } from "/srv/app/src/app/pages/event/view/[username]/[event_name]/index.vue?macro=true";
import { default as indexdwrcHV93svMeta } from "/srv/app/src/app/pages/event/view/[username]/index.vue?macro=true";
import { default as indexQJsxpusMjQMeta } from "/srv/app/src/app/pages/index.vue?macro=true";
import { default as indexqY4SH8vpA7Meta } from "/srv/app/src/app/pages/invitation/index.vue?macro=true";
import { default as unlockqYekZh9LY0Meta } from "/srv/app/src/app/pages/invitation/unlock.vue?macro=true";
import { default as index9gkS52GMPXMeta } from "/srv/app/src/app/pages/legal-notice/index.vue?macro=true";
import { default as index51stNZ3HBKMeta } from "/srv/app/src/app/pages/notification/index.vue?macro=true";
import { default as indexAPy35PSuTdMeta } from "/srv/app/src/app/pages/privacy-policy/index.vue?macro=true";
import { default as indexIOZ53Smz3bMeta } from "/srv/app/src/app/pages/search/index.vue?macro=true";
import { default as createXMh8IOQnlsMeta } from "/srv/app/src/app/pages/session/create.vue?macro=true";
import { default as color_45schemeVL7wjXXrFNMeta } from "/srv/app/src/app/pages/session/edit/[id]/color-scheme.vue?macro=true";
import { default as index0nOf8YlNXRMeta } from "/srv/app/src/app/pages/session/edit/[id]/index.vue?macro=true";
import { default as languageRWazUB6Hd2Meta } from "/srv/app/src/app/pages/session/edit/[id]/language.vue?macro=true";
import { default as _91id_93FvYVN2leaHMeta } from "/srv/app/src/app/pages/session/view/[id].vue?macro=true";
import { default as indexGFCY3io9aFMeta } from "/srv/app/src/app/pages/upload/index.vue?macro=true";
import { default as component_45stubrkVvvFXI8pMeta } from "/srv/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._ee58621ee9d540d917715341c100d0ed/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubrkVvvFXI8p } from "/srv/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.1_jiti@2.4._ee58621ee9d540d917715341c100d0ed/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "🫖___de",
    path: "/de/%F0%9F%AB%96",
    component: () => import("/srv/app/src/app/pages/🫖/index.vue")
  },
  {
    name: "🫖___en",
    path: "/%F0%9F%AB%96",
    component: () => import("/srv/app/src/app/pages/🫖/index.vue")
  },
  {
    name: "account-create___de",
    path: "/de/account/create",
    component: () => import("/srv/app/src/app/pages/account/create.vue")
  },
  {
    name: "account-create___en",
    path: "/account/create",
    component: () => import("/srv/app/src/app/pages/account/create.vue")
  },
  {
    name: "account-edit-username___de",
    path: "/de/account/edit/:username()",
    meta: _91username_93gnmmDyDe2eMeta || {},
    component: () => import("/srv/app/src/app/pages/account/edit/[username].vue")
  },
  {
    name: "account-edit-username___en",
    path: "/account/edit/:username()",
    meta: _91username_93gnmmDyDe2eMeta || {},
    component: () => import("/srv/app/src/app/pages/account/edit/[username].vue")
  },
  {
    name: "account___de",
    path: "/de/account",
    component: () => import("/srv/app/src/app/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/account",
    component: () => import("/srv/app/src/app/pages/account/index.vue")
  },
  {
    name: "account-password-reset___de",
    path: "/de/account/password/reset",
    component: () => import("/srv/app/src/app/pages/account/password/reset/index.vue")
  },
  {
    name: "account-password-reset___en",
    path: "/account/password/reset",
    component: () => import("/srv/app/src/app/pages/account/password/reset/index.vue")
  },
  {
    name: "account-password-reset-request___de",
    path: "/de/account/password/reset/request",
    component: () => import("/srv/app/src/app/pages/account/password/reset/request.vue")
  },
  {
    name: "account-password-reset-request___en",
    path: "/account/password/reset/request",
    component: () => import("/srv/app/src/app/pages/account/password/reset/request.vue")
  },
  {
    name: "account-verify___de",
    path: "/de/account/verify",
    component: () => import("/srv/app/src/app/pages/account/verify.vue")
  },
  {
    name: "account-verify___en",
    path: "/account/verify",
    component: () => import("/srv/app/src/app/pages/account/verify.vue")
  },
  {
    name: "account-view-username___de",
    path: "/de/account/view/:username()",
    meta: _91username_93RZdSdmFdEtMeta || {},
    component: () => import("/srv/app/src/app/pages/account/view/[username].vue")
  },
  {
    name: "account-view-username___en",
    path: "/account/view/:username()",
    meta: _91username_93RZdSdmFdEtMeta || {},
    component: () => import("/srv/app/src/app/pages/account/view/[username].vue")
  },
  {
    name: "attendance___de",
    path: "/de/attendance",
    component: () => import("/srv/app/src/app/pages/attendance/index.vue")
  },
  {
    name: "attendance___en",
    path: "/attendance",
    component: () => import("/srv/app/src/app/pages/attendance/index.vue")
  },
  {
    name: "contact___de",
    path: "/de/contact",
    component: () => import("/srv/app/src/app/pages/contact/index.vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/srv/app/src/app/pages/contact/index.vue")
  },
  {
    name: "dashboard___de",
    path: "/de/dashboard",
    component: () => import("/srv/app/src/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard___en",
    path: "/dashboard",
    component: () => import("/srv/app/src/app/pages/dashboard/index.vue")
  },
  {
    name: "docs-app___de",
    path: "/de/docs/app",
    component: () => import("/srv/app/src/app/pages/docs/app.vue")
  },
  {
    name: "docs-app___en",
    path: "/docs/app",
    component: () => import("/srv/app/src/app/pages/docs/app.vue")
  },
  {
    name: "docs-browser-support___de",
    path: "/de/docs/browser-support",
    component: () => import("/srv/app/src/app/pages/docs/browser-support.vue")
  },
  {
    name: "docs-browser-support___en",
    path: "/docs/browser-support",
    component: () => import("/srv/app/src/app/pages/docs/browser-support.vue")
  },
  {
    name: "event-create___de",
    path: "/de/event/create",
    component: () => import("/srv/app/src/app/pages/event/create.vue")
  },
  {
    name: "event-create___en",
    path: "/event/create",
    component: () => import("/srv/app/src/app/pages/event/create.vue")
  },
  {
    name: "event-edit-username-event_name___de",
    path: "/de/event/edit/:username()/:event_name()",
    meta: _91event_name_933f9GExsCb0Meta || {},
    component: () => import("/srv/app/src/app/pages/event/edit/[username]/[event_name].vue")
  },
  {
    name: "event-edit-username-event_name___en",
    path: "/event/edit/:username()/:event_name()",
    meta: _91event_name_933f9GExsCb0Meta || {},
    component: () => import("/srv/app/src/app/pages/event/edit/[username]/[event_name].vue")
  },
  {
    name: "event___de",
    path: "/de/event",
    component: () => import("/srv/app/src/app/pages/event/index.vue")
  },
  {
    name: "event___en",
    path: "/event",
    component: () => import("/srv/app/src/app/pages/event/index.vue")
  },
  {
    name: "event-view-username-event_name-attendance___de",
    path: "/de/event/view/:username()/:event_name()/attendance",
    meta: attendanceNSqYMMoU5aMeta || {},
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/attendance.vue")
  },
  {
    name: "event-view-username-event_name-attendance___en",
    path: "/event/view/:username()/:event_name()/attendance",
    meta: attendanceNSqYMMoU5aMeta || {},
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/attendance.vue")
  },
  {
    name: "event-view-username-event_name-guest___de",
    path: "/de/event/view/:username()/:event_name()/guest",
    meta: guestqrhruBplTSMeta || {},
    alias: ["/de/event/view/:username/:event_name/invitation"],
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/guest.vue")
  },
  {
    name: "event-view-username-event_name-guest___en",
    path: "/event/view/:username()/:event_name()/guest",
    meta: guestqrhruBplTSMeta || {},
    alias: ["/event/view/:username/:event_name/invitation"],
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/guest.vue")
  },
  {
    name: "event-view-username-event_name___de",
    path: "/de/event/view/:username()/:event_name()",
    meta: indexkrooqXKMT7Meta || {},
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/index.vue")
  },
  {
    name: "event-view-username-event_name___en",
    path: "/event/view/:username()/:event_name()",
    meta: indexkrooqXKMT7Meta || {},
    component: () => import("/srv/app/src/app/pages/event/view/[username]/[event_name]/index.vue")
  },
  {
    name: "event-view-username___de",
    path: "/de/event/view/:username()",
    meta: indexdwrcHV93svMeta || {},
    component: () => import("/srv/app/src/app/pages/event/view/[username]/index.vue")
  },
  {
    name: "event-view-username___en",
    path: "/event/view/:username()",
    meta: indexdwrcHV93svMeta || {},
    component: () => import("/srv/app/src/app/pages/event/view/[username]/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/srv/app/src/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/srv/app/src/app/pages/index.vue")
  },
  {
    name: "invitation___de",
    path: "/de/invitation",
    component: () => import("/srv/app/src/app/pages/invitation/index.vue")
  },
  {
    name: "invitation___en",
    path: "/invitation",
    component: () => import("/srv/app/src/app/pages/invitation/index.vue")
  },
  {
    name: "invitation-unlock___de",
    path: "/de/invitation/unlock",
    meta: unlockqYekZh9LY0Meta || {},
    component: () => import("/srv/app/src/app/pages/invitation/unlock.vue")
  },
  {
    name: "invitation-unlock___en",
    path: "/invitation/unlock",
    meta: unlockqYekZh9LY0Meta || {},
    component: () => import("/srv/app/src/app/pages/invitation/unlock.vue")
  },
  {
    name: "legal-notice___de",
    path: "/de/legal-notice",
    component: () => import("/srv/app/src/app/pages/legal-notice/index.vue")
  },
  {
    name: "legal-notice___en",
    path: "/legal-notice",
    component: () => import("/srv/app/src/app/pages/legal-notice/index.vue")
  },
  {
    name: "notification___de",
    path: "/de/notification",
    component: () => import("/srv/app/src/app/pages/notification/index.vue")
  },
  {
    name: "notification___en",
    path: "/notification",
    component: () => import("/srv/app/src/app/pages/notification/index.vue")
  },
  {
    name: "privacy-policy___de",
    path: "/de/privacy-policy",
    component: () => import("/srv/app/src/app/pages/privacy-policy/index.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/srv/app/src/app/pages/privacy-policy/index.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/srv/app/src/app/pages/search/index.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/srv/app/src/app/pages/search/index.vue")
  },
  {
    name: "session-create___de",
    path: "/de/session/create",
    component: () => import("/srv/app/src/app/pages/session/create.vue")
  },
  {
    name: "session-create___en",
    path: "/session/create",
    component: () => import("/srv/app/src/app/pages/session/create.vue")
  },
  {
    name: "session-edit-id-color-scheme___de",
    path: "/de/session/edit/:id()/color-scheme",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/color-scheme.vue")
  },
  {
    name: "session-edit-id-color-scheme___en",
    path: "/session/edit/:id()/color-scheme",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/color-scheme.vue")
  },
  {
    name: "session-edit-id___de",
    path: "/de/session/edit/:id()",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/index.vue")
  },
  {
    name: "session-edit-id___en",
    path: "/session/edit/:id()",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/index.vue")
  },
  {
    name: "session-edit-id-language___de",
    path: "/de/session/edit/:id()/language",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/language.vue")
  },
  {
    name: "session-edit-id-language___en",
    path: "/session/edit/:id()/language",
    component: () => import("/srv/app/src/app/pages/session/edit/[id]/language.vue")
  },
  {
    name: "session-view-id___de",
    path: "/de/session/view/:id()",
    component: () => import("/srv/app/src/app/pages/session/view/[id].vue")
  },
  {
    name: "session-view-id___en",
    path: "/session/view/:id()",
    component: () => import("/srv/app/src/app/pages/session/view/[id].vue")
  },
  {
    name: "upload___de",
    path: "/de/upload",
    component: () => import("/srv/app/src/app/pages/upload/index.vue")
  },
  {
    name: "upload___en",
    path: "/upload",
    component: () => import("/srv/app/src/app/pages/upload/index.vue")
  },
  {
    name: component_45stubrkVvvFXI8pMeta?.name,
    path: "/de/sitemap.xml",
    component: component_45stubrkVvvFXI8p
  },
  {
    name: component_45stubrkVvvFXI8pMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubrkVvvFXI8p
  }
]